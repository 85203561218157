export const initialState = {
    resetPassword : '',
    resetLoading : false,
    getFedSsoUserDetails:{},
    fedSsouserStatus:0
  }
  
  
  export const resetPwdReducer = (state = initialState,action) =>{
      switch (action.type) {
            case "RESET_PASSWORD" :
            return{
              ...state,
              resetPassword: action.resetPassword
            }
            case "RESET_LOADING" :
            return {
                ...state,
                resetLoading : action.resetLoading
            }
            case "RESET_DATA" : 
            return {
              ...state,
              resetPassword: ""
            }
            case "LOAD_FED_SSO_DETAILS":
              return{
                ...state,
                getFedSsoUserDetails: action.data,
                fedSsouserStatus: action.data.status
              } 
            case "CLEAR_FED_STATUS":
              return{
                ...state,
                fedSsouserStatus: 0
              }  
          default:
                return state;
    }
  }