import {postApiCall,getApiCall} from '../utils/index'

export const invokeResetPasswordAPI = (url,payload) => {
    return dispatch => {
        dispatch({ type: "RESET_LOADING", resetLoading : true})
      postApiCall(url, payload).then(res => {
            return res; 
      }).then(res => {
        if(res.status === 200){
          dispatch({ type: "RESET_PASSWORD", resetPassword : "Success"})
        }
        else{
          dispatch({ type: "RESET_PASSWORD", resetPassword : "Failure"})
        }
        dispatch({ type: "RESET_LOADING", resetLoading : false})
        // if(res.ResetPasswordRes.ResMessage === 'Success'){
        //   dispatch({ type: "RESET_PASSWORD", resetPassword : res.ResetPasswordRes.ResMessage})
        // }else{
        //   dispatch({ type: "RESET_PASSWORD", resetPassword : res.ResetPasswordRes.ErrorMessage})
        // }
        // dispatch({ type: "RESET_LOADING", resetLoading : false})
      }
      ).catch(err => {
        // Do something for an error here
        window.open('/errorpage','_self')
      });
    }
  }

  export const resetData = () =>{
    return dispatch => {
      dispatch({type : "RESET_DATA"})
    }
  }

  export const getFedSSOUserDetails = (url) => {
    return dispatch => {
      dispatch({ type : "LOADING" , loadingStarts : true})
      getApiCall(url).then(res => {
        return res.json();
      }).then(res => {
        dispatch({ type: "LOAD_FED_SSO_DETAILS", data: res });
        dispatch({ type : "LOADING" , loadingStarts : false})
      }
      ).catch(err => {
        // Do something for an error here
          window.open('/errorpage','_self')
      });
    }
  }

  export const resetFedSsoStatus = () => {
    return dispatch => {
      dispatch({  type : "CLEAR_FED_STATUS"})
    }
  }
  