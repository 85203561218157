export const initialState = {
    loggedInUserInfo : null,
    pwdResponse : '',
    oldPwdValidateLoader : '',
    checkPwdComplexityRes : '',
    updatePwdRes : '',
    setPwdRes : '',
    newPwdValidateLoader : '',
    unlockedUserInfo : {},
    unlockInfoLoader : false,
    fedSSOUserDetails:{},
    fedSsouserStatus:0,
    isloadingData:false
  }
  
  
  export const changePwdReducer = (state = initialState,action) =>{
      switch (action.type) {
            case "GET_ACCOUNT_INFO" :
            return{
              ...state,
              loggedInUserInfo: action.loggedInUserInfo
            }
            case 'PASSWORD_RESPONSE' :
            return {
              ...state,
              pwdResponse : action.pwdResponse
            }
            case 'REMOVE_OLD_PWD_ERROR' :
            return {
              ...state,
              pwdResponse : '',
              updatePwdRes : ''
            }
            case 'REMOVE_NEW_PWD_ERROR' :
            return{
              ...state,
              updatePwdRes : ''
            }
            case 'OLD_PWD_LOADER' :
            return {
              ...state,
              oldPwdValidateLoader : action.oldPwdValidateLoader,
            }
            case "RESET_CHANGE_PWD_VALUES" : 
            return {
              ...state,
              pwdResponse : '',
              oldPwdValidateLoader : '',
              updatePwdRes : ''
            }
            case "CHECK_PWD_COMPLEXITY" : 
            return{
              ...state,
              checkPwdComplexityRes : action.checkPwdComplexityRes
            }
            case "REMOVE_PWD_COMPLEXITY_ERR" : 
            return{
              ...state,
              checkPwdComplexityRes : ''
            }
            case "UPDATE_PASSWORD" : 
            return{
              ...state,
              updatePwdRes : action.updatePwdRes
            }
            case "SET_PASSWORD" : 
            return{
              ...state,
              setPwdRes : action.setPwdRes
            }
            case "NEW_PWD_LOADER" :
              return{
                ...state,
                newPwdValidateLoader : action.newPwdValidateLoader
              }
            case "GET_UNLOCKED_USER_INFO" :
            return {
              ...state,
              unlockedUserInfo : action.unlockedUserInfo
            }
            case "GET_UNLOCKINFO_LOADER" : 
            return {
              ...state,
              unlockInfoLoader : action.unlockInfoLoader
            }
          case "LOAD_FED_SSO_DETAILS":
            return {
              ...state,
             fedSSOUserDetails: action.data,
             fedSsouserStatus:action.data.status
            }
          case "GET_LOADING_DATA":
            return {
              ...state,
              isloadingData: action.loading
            }
          default:
                return state;
    }
  }