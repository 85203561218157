import React from "react"
import FooterHyperlinksComponent from './FooterHyperlinksComponent'
import BarLoader from 'react-spinners/BarLoader';
//Redux
import { invokeResetPasswordAPI, resetData,getFedSSOUserDetails,resetFedSsoStatus} from '../actions/resetPasswordAction'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import DisclaimerDomainDialog from "./DisclaimerDialogComponent";

class ResetPasswordComponent extends React.Component{
    constructor(props){
    super(props);
    this.state = {
        incorrectEmailAdd : false,
        errorInternalUsers : false,
        erroremailAdd:false,
        emailAdd : "",
        enableSubmit : false
    }
    this.resetPassword = this.resetPassword.bind(this);
}

componentDidMount = () => {
    this.props.resetData();
    window.scrollTo(0,0);
}
    onChangeField = (event) =>{
    this.props.resetData();
    this.setState({[event.target.name]: event.target.value},() => {
        if(this.state.emailAdd.trim() === ""){
            this.setState({ erroremailAdd: true}, () => {
                this.validateOnSubmit();
            });
        }
    }); 
    this.validateEmail(event);
    event.target.value.length === 0 && this.props.resetFedSsoStatus();
    if(event.target.value.length > 5 && !this.state.incorrectEmailAdd){
        this.fetchFedSSODetails(event.target.value); 
    }
}

//This function is used to invoke resetPassword API
resetPassword = () => {
    var resetPwdPayload = {
        "emailAddress" : this.state.emailAdd
    }
    this.props.invokeResetPasswordAPI('/resetPassword',resetPwdPayload)
}

fetchFedSSODetails = (value) => {
    this.props.getFedSSOUserDetails(`/getFedSSOUserDetails/${value}`)
}

/*****************************************************************
     * Triggered : On blur of email address text box 
     * Description : This function is used to validate email address.
******************************************************************/

    validateEmail = event => {
        
        // eslint-disable-next-line
        let letters = /^\w+([\.]?[-]?[\.-]\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;

		if (event.target.name === "emailAdd") {
			if (event.target.value.match(letters)) {
                if((event.target.value.endsWith("@juniper.net", event.target.value.length) || event.target.value.endsWith("@jnpr.net", event.target.value.length))){
                    this.setState({ incorrectEmailAdd: false,
                        errorInternalUsers: true,
                        erroremailAdd:false}, () => {
                            this.validateOnSubmit();
                    });
                }else{
                    this.setState({ incorrectEmailAdd: false,
                        errorInternalUsers: false,
                        erroremailAdd:false}, () => {
                            this.validateOnSubmit();
                    });
                }
			} else {
                this.setState({ incorrectEmailAdd: true,
                    errorInternalUsers: false,
                    erroremailAdd:false}, () => {
                        this.validateOnSubmit();
                    });
			}
            
        }      
    }

    validateOnSubmit = () => {
        if(!this.state.erroremailAdd && !this.state.incorrectEmailAdd && this.state.emailAdd !=="" && !this.state.errorInternalUsers){
            this.setState({enableSubmit : true})
        }
        else{
            this.setState({enableSubmit : false})
        }
	}
    closeDialog(){
        this.props.resetData();
    }
    render(){
        let {resetLoading } = this.props;
        const accountText = (
            <div className="padding-around-small">
                <h3>You are almost done!</h3>
                <p> We Just sent you an email to <span style={{color:"#039BE5"}}>{this.state.emailAdd}</span></p>
                
                <ol className="margin-around text-align-justify">
                    <p style={{marginLeft : "-1em"}}>To complete the process of changing your password :</p>
                    <li>Check your email at <span className ="margin-left-small" style={{color:"#039BE5"}}>{this.state.emailAdd}</span></li>
                    <li>Follow the instructions as per the email.</li>
                </ol>
            </div>

        )
        return(
            <div className="body-container"> 
                <div className="grid padding-around font-size-medium center-element width-80" >
                    <div className="col-12">
                        <p className="font-size-xlarge text-uppercase"><strong>User Registration</strong></p><br/>
                        <p className="account-creation-n"><strong>Forgot Your Password?</strong></p><br />
                        <p className="col-7">Request an email NOTIFICATION, with a link to unlock your account and change your password.</p><br/>
                        <div className="grid">
                            <div className="col-6">
                                <p className="text-input-label">Email Address <span className="text-required">*</span></p>
                                <div className="rectangle-auth">
                                        <input type="text" placeholder="Type here..." name="emailAdd" value={this.state.emailAdd} 
                                        onChange={this.onChangeField}  disabled = {resetLoading} required />
                                        <BarLoader  width={"50%"} color={'#84B135'} loading={resetLoading} />
										{ this.state.erroremailAdd && <p className="font-size-small text-color-red">
                                        <i className="fas fa-sm fa-exclamation-circle"></i> Please provide Email Address!</p>}
										{ !this.state.erroremailAdd && this.state.incorrectEmailAdd && 
                                        <p className="font-size-small text-color-red">
                                        <i className="fas fa-sm fa-exclamation-circle"></i> Invalid Email Address!</p>}
                                        { this.state.errorInternalUsers && !this.state.incorrectEmailAdd && !this.state.erroremailAdd &&
                                        <p className="font-size-small text-color-red">
                                        <i className="fas fa-sm fa-exclamation-circle"></i> Reset Password is not allowed for Internal Users!</p>}
                                        { !this.state.erroremailAdd && !this.state.incorrectEmailAdd && (this.props.resetPassword === "Failure") &&
                                        <p className="font-size-small text-color-red">
                                        <i className="fas fa-sm fa-exclamation-circle"></i>
                                         Invalid User Status. Please contact customer care for further assistance.
                                        </p>}
                                        {this.props.fedSsoUserDetails  && this.props.fedSsouserStatus === 200 &&
                                         <p className="font-size-small text-color-red">
                                         <i className="fas fa-sm fa-exclamation-circle"></i>
                                         Your password cannot be changed by Juniper because your login domain is Federation enabled. Use your corporate account management tools to make password changes.
                                         </p> }
								</div>
                            </div>
                        </div><br/>
                        <div>
                            <button  className={(this.state.enableSubmit && !resetLoading && (this.props.resetPassword !== "Failure") && this.props.fedSsouserStatus !== 200
                            ? "primary rectangle-back-button" : 
                            "primary disabled rectangle-back-button")} onClick={this.resetPassword}><span className="back">Submit </span>
                            </button>
						</div>
                        <div className="margin-top-largest">
                        <FooterHyperlinksComponent history={this.props.history}/>
                        </div>
                    </div>
                </div>
                <DisclaimerDomainDialog dialogName = "confirmation-dialog" accountTitle={"RESET PASSWORD CONFIRMATION"} accountText={accountText}
                openDialog = {this.props.resetPassword === "Success" ? true :false} closeDialog = {this.closeDialog.bind(this)}history = {this.props.history}/>
            </div>
        )
    }
}

/************************************************************************
 * 1. mapStateToProps : This function is used to map state of Redux layer
 * 					 to React layer as props.
 * 					 props value : state
 ***********************************************************************/
function mapStateToProps(state) {
    return {
        resetPassword : state.resetPwdReducer.resetPassword,
        resetLoading : state.resetPwdReducer.resetLoading,
        fedSsoUserDetails : state.resetPwdReducer.getFedSsoUserDetails,
        fedSsouserStatus: state.resetPwdReducer.fedSsouserStatus
    }
}

/*************************************************************************
 * 2. mapDispatchToProps : This function lets you create functions that dispatch
 * 						when called, and pass those functions as props to
 * 					    your component.
 *************************************************************************/

const mapDispatchToProps = dispatch => bindActionCreators({
    invokeResetPasswordAPI,
    resetData,
    getFedSSOUserDetails,
    resetFedSsoStatus
}, dispatch)

//3. Connect (mapStateToProps | mapDispatchToProps ) to (React Component)
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordComponent)